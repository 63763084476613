
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useStore } from '../../store'
import { memorableEvents } from '@/api/home'
import GlobalNumber from '@/components/GlobalNumber.vue'
export default defineComponent({
  components: {
    GlobalNumber
  },
  setup() {
    const list = ref(null)
    const yearsList = ref(Array)
    const memorableList = ref(null)
    const story = ref()
    const isFixed = ref(false)
    const active = ref(0)
    const globaNumberName = ref('')
    const store = useStore()
    const device = computed(() => store.state.device)
    onMounted(async () => {
      window.addEventListener('scroll', handleScroll)
      getIncubateFunc()
    })
    const getIncubateFunc = async () => {
      const data = await memorableEvents()
      list.value = data.data
      yearsList.value = data.data.years
      memorableList.value = data.data.memorableEvents
    }

    const formatValue = computed(() => {
      return (str: string) => {
        return str.replace(/\n/g, '<br>')
      }
    })
    const handleScroll = async () => {
      try {
        interface DOMRectProps {
          DOMRect: { top: number }
        }
        const about = (document.getElementById(
          'yeji'
        ) as HTMLElement).getBoundingClientRect().top
        console.log(about, store.state.device)
        if (store.state.device === 'desktop' && about <= 700) {
          globaNumberName.value = 'GlobalNumber'
        }
        if (store.state.device === 'mobile' && about <= 120) {
          globaNumberName.value = 'GlobalNumber'
        }

        const storyTop = story.value.getBoundingClientRect().top
        if (storyTop <= 0) isFixed.value = true
        if (storyTop > 0) {
          isFixed.value = false
        }
        for (let i = yearsList.value.length - 1; i >= 0; i--) {
          const ids = 'd' + i
          const yearsDomTop = document.getElementById(ids)
            ? (document.getElementById(
              ids
            ) as HTMLElement).getBoundingClientRect().top
            : 0
          if (yearsDomTop < 100) {
            active.value = i
            break
          }
        }
      } catch {}
    }
    const changeActive = (index: number) => {
      active.value = index
    }
    return {
      changeActive,
      globaNumberName,
      active,
      isFixed,
      story,
      list,
      yearsList,
      memorableList,
      formatValue,
      device
    }
  }
})
