
import { defineComponent, onMounted, ref } from 'vue'
type ValueType = number
type interValueProps = any // | NodeJS.Timer | null需要node版本一致，暂时用any替代了
interface ISwitchProps {
  time: ValueType
  value: ValueType
}

export default defineComponent({
  props: {
    time: {
      type: Number,
      default: 2
    },
    value: {
      type: Number,
      default: 0
    }
  },
  setup(props: ISwitchProps) {
    const numberGrowRef = ref()
    onMounted(() => {
      numberGrow(numberGrowRef.value)
    })
    const numberGrow = (ele: HTMLElement) => {
      const step = (props.value * 10) / (props.time * 1000)
      console.log(step)
      let current: ValueType = 0
      let start: ValueType = 0
      let t: interValueProps = setInterval(() => {
        start += step
        if (start > props.value) {
          clearInterval(Number(t))
          start = props.value
          t = null
        }
        if (current === start) {
          return
        }
        current = start
        ele.innerHTML = current
          .toFixed(0)
          .toString()
          .replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, '$1,')
      }, 0.1)
    }
    return {
      numberGrowRef,
      numberGrow
    }
  }
})
