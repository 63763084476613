<template>
  <div class="meet-list-page">
    <div class="meet-list-page-list">
      <div class="meet-list-page-list-item" @click="next">
        <img src="" alt="" class="meet-list-page-list-item-logo" />
        <div class="meet-list-page-list-item-header">
          <p class="meet-list-page-list-item-header-name">会议室I-领航</p>
          <div class="meet-list-page-list-item-header-handle">预定</div>
        </div>
        <div class="meet-list-page-list-item-content">
          <div class="meet-list-page-list-item-content-left">
            <img src="" alt="" />
          </div>
          <div class="meet-list-page-list-item-content-right">
            <p class="meet-list-page-list-item-content-right-title">
              会议室I-领航
            </p>
            <p class="meet-list-page-list-item-content-right-text">
              位置: 三区南楼
            </p>
            <p class="meet-list-page-list-item-content-right-text">
              使用范围：8-10人
            </p>
            <p class="meet-list-page-list-item-content-right-text">
              使用状态：正在使用中
            </p>
          </div>
        </div>
      </div>
      <div class="meet-list-page-list-item" @click="next">
        <img src="" alt="" class="meet-list-page-list-item-logo" />
        <div class="meet-list-page-list-item-header">
          <p class="meet-list-page-list-item-header-name">会议室I-领航</p>
          <div class="meet-list-page-list-item-header-handle">预定</div>
        </div>
        <div class="meet-list-page-list-item-content">
          <div class="meet-list-page-list-item-content-left">
            <img src="" alt="" />
          </div>
          <div class="meet-list-page-list-item-content-right">
            <p class="meet-list-page-list-item-content-right-title">
              会议室I-领航
            </p>
            <p class="meet-list-page-list-item-content-right-text">
              位置: 三区南楼
            </p>
            <p class="meet-list-page-list-item-content-right-text">
              使用范围：8-10人
            </p>
            <p class="meet-list-page-list-item-content-right-text">
              使用状态：正在使用中
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { useRouter, useRoute } from 'vue-router'
export default defineComponent({
  setup() {
    const router = useRouter()
    const next = () => {
      // router.push('/meet/meet-detail')
    }
    return {
      next
    }
  }
})
</script>

<style lang="scss">
::v-deep.app-vue {
 width: 100%;
 height: 100%;
}
body,
html {
 width: 100%;
 height: 100%;
}
.app-vue {
  width: 100%;
  height: 100%;
}
.meet-list-page {
  width: 100%;
  height: 100%;
  background: url('../../../assets/meet/u1.png') no-repeat;
  background-size: cover;
  padding: 15px;
  &-list {
    &-item {
      position: relative;
      &-logo {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 0px;
        border-radius: 50%;
        top: 5px;
        background: red;
      }
      &-header {
        display: flex;
        justify-content: space-between;
        padding: 5px 5px 5px 30px;
        align-items: center;
        border-bottom: 1px solid #000;
        &-img {
        }
        &-name {
          color: #000;
          font-size: 18px;
        }
        &-handle {
          width: 100px;
          height: 25px;
          text-align: center;
          line-height: 25px;
          background: #349cfe;
          border-radius: 20px;
          font-size: 14px;
          color: #fff;
        }
      }
      &-content {
        display: flex;
        padding: 20px 0;
        &-left {
          width: 155px;
          height: 111px;
          background: red;
          border-radius: 5px;
        }
        &-right {
          margin-left: 15px;
          color: #000;
          padding: 20px;
          &-title {
            font-size: 16px;
            margin-bottom: 15px;
          }
          &-text {
            font-size: 12px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
