
import { dealTime } from '@/utils/utils'
import { defineComponent, ref, onMounted, computed } from 'vue'
// import { sitesList } from '@/api/home'
import { getSiteList, getMeetingRoomList, getMeetingDetail, cancelMeeting, editMeeting } from '@/api/meet'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'
import { Toast } from 'vant'
import moment from 'moment'
export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const siteName = ref('')
    const meetingRoomName = ref('')
    const meetingRoomId = ref('')
    const meetingName = ref('')
    const bookingPersonName = ref('')
    const meetingEndTime = ref('')
    const meetingStartTime = ref('')
    const meetingInfo = ref()
    const siteList = ref([])
    const siteNameList = ref()
    const meetingRoomList = ref()
    const meetingRoomNameList = ref()
    const showPicker = ref(false)
    const siteNamePicker = ref(false)
    const meetingRoomNamePicker = ref(false)
    const startOrEnd = ref()
    const currentDatetime = ref()
    const tel = ref()
    const createdId = ref()
    const isEdit = ref(true)
    const meetingroom = ref()
    const deleteMeet = ref(false)
    const isOneself = ref(false)

    // 站点
    const getSiteListFunc = async () => {
      const temp = (await getSiteList()).data
      // siteList.value = temp.map((el :any) => {
      //   console.log(el.incubators.length)
      //   if (el.incubators.length > 0) {
      //     return el.incubators
      //   }
      //   return []
      // }).flat()
      // console.log(siteList.value)
      siteList.value = temp
      siteNameList.value = siteList.value.map((el : any) => {
        return el.siteName
      })
    }
    // 会议室列表
    const getMeetingRoomListFunc = async (siteId:string|number) => {
      const data = await getMeetingRoomList({ siteId })
      meetingRoomList.value = data.data
      meetingRoomNameList.value = meetingRoomList.value.map((el : any) => {
        return el.meetingRoomName
      })
    }
    const getMeetingDetailFunc = async () => {
      // const data = await getMeetingDetail(<string>route.query.meetingId)
      const data:any = await getMeetingDetail({ meetingId: route.query.meetingId, appId: store.state.openId || window.localStorage.getItem('openid') })
      siteName.value = data.data.siteName
      meetingRoomName.value = data.data.meetingRoomName
      meetingName.value = data.data.meetingName
      bookingPersonName.value = data.data.bookingPersonName
      createdId.value = data.data.createdId
      tel.value = data.data.tel
      meetingStartTime.value = moment(data.data.meetingStartTime).format('YYYY-MM-DD HH:mm') // String(data.data.meetingStartTime).replace('T', ' ').slice(0, 16)
      meetingEndTime.value = moment(data.data.meetingEndTime).format('YYYY-MM-DD HH:mm') // String(data.data.meetingEndTime).replace('T', ' ').slice(0, 16)
      meetingInfo.value = data.data

      const nowTime = Number(Math.round(new Date() as any / 1000))
      const endTime = Math.round(new Date(data.data.meetingEndTime) as any / 1000)
      isOneself.value = endTime - nowTime > 0 && store.state.openId === createdId.value
    }
    onMounted(() => {
      getSiteListFunc()
      getMeetingRoomListFunc(store.state.siteId)
      // console.log('=====>', typeof route.query.meetingId)
      if (route.query.meetingId) {
        getMeetingDetailFunc()
        store.commit('SET_OPENID', window.localStorage.getItem('openid'))
      }
    }
    )
    const cancel = () => {
      deleteMeet.value = false
    }
    const confirm = async () => {
      deleteMeet.value = false
      const openId:string|null|number = store.state.openId || window.localStorage.getItem('openid') || ''
      await cancelMeeting({
        id: meetingInfo.value.id,
        appId: openId
      }).then((res:any) => {
        if (res.returnCode === '2000') {
          Toast.success('取消会议成功')
          router.back()
        }
      })
    }
    const siteNamOnConfirm = async (e:string) => {
      // console.log(e)
      siteName.value = e
      siteNamePicker.value = false
      const siteID : any = siteList.value.find((el:any) => {
        return el.siteName === siteName.value
      })
      await getMeetingRoomListFunc(siteID ? siteID.id : '')
      // 每次默认赋值
      meetingRoomName.value = meetingRoomNameList.value[0]
      // 这里选择站点是否存储
    }
    const meetingRoomNameOnConfirm = async (e:string) => {
      // console.log(e)
      meetingRoomName.value = e
      meetingRoomNamePicker.value = false
    }
    const cancelMeet = async () => {
      // siteId: meetingInfo.value.siteId,
      //   id: meetingInfo.value.id,
      //   appId: openId
      if (isEdit.value) {
        deleteMeet.value = true
      } else {
        isEdit.value = true
      }
    }
    const editMeet = async () => {
      // 表单验证
      if (!isEdit.value) {
        meetingroom.value.validate().then(async () => {
          // 处理站点
          const siteID : any = siteList.value.find((el:any) => {
            return el.siteName === siteName.value
          })
          // 处理房间号
          const roomID : any = meetingRoomList.value.find((el:any) => {
            return el.meetingRoomName === meetingRoomName.value
          })
          const openId:string|null|number = store.state.openId || window.localStorage.getItem('openid') || ''

          await editMeeting({
            appId: openId,
            bookingPerson: bookingPersonName.value,
            id: meetingInfo.value.id,
            meetingEndTimeStr: meetingEndTime.value.replace(' ', 'T'),
            meetingName: meetingName.value,
            meetingRoomId: roomID ? roomID.id : meetingInfo.value.meetingRoomId,
            meetingStartTimeStr: meetingStartTime.value.replace(' ', 'T'),
            siteId: siteID ? siteID.id : meetingInfo.value.siteId,
            tel: tel.value
          }).then((res:any) => {
            if (res.returnCode === '2000') {
              console.log(res)
              Toast.success('编辑会议成功')
              router.back()
              // 记录房间信息
              store.commit('SET_SELECTMRRTINFO', { name: meetingRoomName.value, id: null, itemid: roomID.id })
              console.log(store.state.selectMeetInfo)
            }
          })
        })
      } else {
        isEdit.value = false
      }
    }
    const openTimePicker = (e:number) => {
      showPicker.value = true
      startOrEnd.value = e
      if (e) {
        currentDatetime.value = new Date(meetingEndTime.value)
      } else {
        currentDatetime.value = new Date(meetingStartTime.value)
      }
    }
    const onConfirm = (e:any) => {
      if (startOrEnd.value) {
        meetingEndTime.value = dealTime(new Date(e).getTime()).replace('T', ' ')
      } else {
        meetingStartTime.value = dealTime(new Date(e).getTime()).replace('T', ' ')
      }
      showPicker.value = false
    }
    const filter = (type:any, options:any) => {
      if (type === 'minute') {
        return options.filter((option:any) => option % 30 === 0)
      }
      return options
    }
    return {
      currentDatetime,
      filter,
      siteName,
      meetingRoomName,
      meetingRoomId,
      meetingName,
      bookingPersonName,
      meetingStartTime,
      meetingEndTime,
      showPicker,
      siteNamePicker,
      meetingRoomNamePicker,
      siteNamOnConfirm,
      meetingRoomNameOnConfirm,
      cancelMeet,
      editMeet,
      openTimePicker,
      siteNameList,
      meetingRoomList,
      meetingRoomNameList,
      meetingInfo,
      startOrEnd,
      onConfirm,
      tel,
      createdId,
      isEdit,
      meetingroom,
      deleteMeet,
      cancel,
      confirm,
      isOneself
    }
  }
})
