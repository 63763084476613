
import { defineComponent, onMounted, ref } from 'vue'
import { useStore } from '@/store'
// import { getActiveList } from '@/api/meet'
import { getSiteList } from '@/api/meet'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const store = useStore()
    const siteList = ref([])
    const router = useRouter()
    const next = (e:string|number) => {
      store.commit('SET_SITEID', e)
      router.push({
        path: '/meet/meet-detail',
        query: {
          id: e
        }
      })
    }
    // const getActiveListFunc = async () => {
    //   const data = await getActiveList()
    // }
    const getSiteListFunc = async () => {
      const temp = (await getSiteList()).data
      // siteList.value = temp.map((el :any) => {
      //   console.log(el.incubators.length)
      //   if (el.incubators.length > 0) {
      //     return el.incubators
      //   }
      //   return []
      // }).flat()
      console.log(temp)
      siteList.value = temp
      // console.log(siteList.value)
    }
    onMounted(async () => {
      // getActiveListFunc()
      getSiteListFunc()
    })
    return {
      next,
      siteList
    }
  }
})
