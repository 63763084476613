import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "number-grow-warp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("span", {
      ref: "numberGrowRef",
      "data-time": _ctx.time,
      class: "number-grow",
      "data-value": _ctx.value
    }, "0", 8, ["data-time", "data-value"])
  ]))
}